import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  privacyData: any ;
  first:string = "active"
  second:string = ""



  constructor(private spinner: NgxSpinnerService, 
    public translate: TranslationService,
    private pagesService: PagesService) { }

  ngOnInit(): void {
    this.spinner.show();
      // setTimeout(() => {
      //   this.spinner.hide();
      // }, 2000);

    this.pagesService.getAbout().subscribe(
      (response: any) => {
        this.privacyData = response.data[0];
        console.log("caravan",this.privacyData);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  onActiveS=()=>{
    this.second = "active"
    this.first = ""
  }

  onActiveF=()=>{
    this.first = "active"
    this.second = ""
  }
}
