<div class="container">
  <div [ngClass]="{'privatePolicy': translate.is_arabic, 'privatePolicyEn' : !translate.is_arabic}">
    <div class="row">
      <div class="col-3 d-lg-inline d-none">
        <div class="header-menu">
          <p>{{translate.data.privacy.privacy}}</p>
        </div>
        <div class="body-menu">
          <ul>
            <li><p [class]="first" (click) = onActiveF()>{{translate.data.privacy.PaymentPolicy}} </p></li>
            <li><p [class]="second" (click) = onActiveS()>{{translate.data.privacy.Participationpolicy}}</p></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-9 col-12">
        <div class="header-content">
          <p class="d-lg-inline d-none">{{translate.data.privacy.general}}</p>
          <p class="d-lg-none d-inline">{{translate.data.privacy.privacy}}</p>
        </div>
        <div class="body-content">
          <p class="active mb-3 d-lg-none d-inline">{{translate.data.CommonQuestions.generalQuestions}}</p>
          <div>
            <p class="answer" [innerHTML]="privacyData ? privacyData[translate.data.requestdData.genPrivacy] : '' " *ngIf="first"></p>
            <p class="answer" [innerHTML]="privacyData ? privacyData[translate.data.requestdData.genTerms] : '' " *ngIf="second"></p>
          </div>
          <div class="Pagination">
            <img class="right" src="../../../assets/Pagination/Group 10357.svg" alt="">
            <img class="left" src="../../../assets/Pagination/Group 10358.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
